export default {
  isNull(object) {
    if (object === undefined || object === "") {
      return true;
    }
    return (
      Object.keys(object).length === 0 &&
      JSON.stringify(object) === JSON.stringify({})
    );
  },
};
