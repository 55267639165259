import Vue from "vue";
import VueCookies from "vue-cookies";


const USER_DATA = () => {
  return {
    account: "",
    authToken: "",
    belongcompany: "",
    belongcompany_cd: "",
    grade: "",
    name: "",
    service: "",
  };
};

const mutations = {
  drawer(state, payload) {
    state.drawer = payload;
  },
  sessionId(state, payload) {
    state.sessionId = payload;
  },
  login(state, payload) {
    // 로그인관련 데이터 저장
    state.isLogin = true;
    state.userInfo = { ...payload };

    // console.log("login mutation")
    // console.log(payload)
    // login 성공, token 을 설정 
    Vue.axios.defaults.headers.common['authorization'] = `Bearer ${payload.auth_token}`;
    // cookie 설정
    VueCookies.set("auth_token" ,payload.auth_token,"30d")
    VueCookies.set("account" ,payload.account,"30d")
    VueCookies.set("name" ,payload.name,"30d")
    VueCookies.set("grade" ,payload.grade,"30d")
    // console.log("payload",payload)
    // 등급에 따른 접근 권한
    // let list = [];
    // 수퍼바이저
    // if (payload.grade < "UT02") {
    //     list = [
    //       { icon: 'mdi-home', econ: 'mdi-blank', active: false, title: '모니터 홈', link: "/home", },
    //       { icon: 'mdi-flag-checkered', active: false, title: '농장/사업장 정보',
    //         items:  [
    //                   { link: "/farminfo/listcompanies", icon: "mdi-city", title: "경영체 목록",},
    //                   { link: "/farminfo/listfarms", icon: "mdi-satellite", title: "농장/사업장 목록" },
    //                   { link: "/farminfo/listbuildings", icon: "mdi-bank", title: "축사/건물/구역 목록" },
    //                   { link: "/farminfo/listdealcompanies", icon: "mdi-truck", title: "거래처 목록",},
    //                   { link: "/farminfo/room", icon: "mdi-view-dashboard", title: "돈방/위치 목록" },
    //                 ],
    //       },
    //       { icon: 'mdi-account-multiple', econ: 'mdi-blank', active: false, title: '사용자 정보',link: "/user/registeruser", },
    //       { icon: 'mdi-monitor-eye', active: false, title: '센서 상태',
    //         items:  [
    //                   { link: "/device/listsensoronoff", icon: "mdi-access-point-network", title: "센서 수신 목록" },
    //                   { link: "/device/monitorsensor", icon: "mdi-chart-line", title: "센서 수신 DATA" },
    //                   // { link: "/device/registersensor", icon: "mdi-plus-network", title: "센서 현장 등록" },
    //                 ],
    //       },
    //       { icon: 'mdi-usb', econ: 'mdi-blank', active: false, title: '장치 등록',link: "/device/registerdevice", },
    //       { icon: 'mdi-account-key', econ: 'mdi-blank', active: false, title: '내정보 확인/변경', link: "/user/myinfo", },
    //       { icon: 'mdi-brightness-7', econ: 'mdi-blank', active: false, title: '정보 설정', link: "/setinfo", },
    //       { icon: "mdi-information-variant", econ: 'mdi-blank', active: false, title: "About", link: "/about", },
    //     ]

    // // 시스템 관리자
    // } else if (payload.grade < "UT04") {
    //     list = [
    //       { icon: 'mdi-home', econ: 'mdi-blank', active: false, title: '모니터 홈', link: "/home", },
    //       { icon: 'mdi-flag-checkered', active: false, title: '농장/사업장 정보',
    //         items:  [
    //                   { link: "/farminfo/listcompanies", icon: "mdi-city", title: "경영체 목록",},
    //                   { link: "/farminfo/listfarms", icon: "mdi-satellite", title: "농장/사업장 목록" },
    //                   { link: "/farminfo/listbuildings", icon: "mdi-bank", title: "축사/건물/구역 목록" },
    //                   { link: "/farminfo/listdealcompanies", icon: "mdi-truck", title: "거래처 목록",},
    //                   { link: "/farminfo/room", icon: "mdi-view-dashboard", title: "돈방/위치 목록" },
    //                 ],
    //       },
    //       { icon: 'mdi-account-multiple', econ: 'mdi-blank', active: false, title: '사용자 정보',link: "/user/registeruser", },
    //       { icon: 'mdi-monitor-eye', active: false, title: '센서 상태',
    //         items:  [
    //                   { link: "/device/listsensoronoff", icon: "mdi-access-point-network", title: "센서 수신 목록" },
    //                   { link: "/device/monitorsensor", icon: "mdi-chart-line", title: "센서 수신 DATA" },
    //                   // { link: "/device/registersensor", icon: "mdi-plus-network", title: "센서 현장 등록" },
    //                 ],
    //       },
    //       { icon: 'mdi-usb', econ: 'mdi-blank', active: false, title: '장치 등록',link: "/device/registerdevice", },
    //       { icon: 'mdi-account-key', econ: 'mdi-blank', active: false, title: '내정보 확인/변경', link: "/user/myinfo", },
    //       { icon: 'mdi-brightness-7', econ: 'mdi-blank', active: false, title: '정보 설정', link: "/setinfo", },
    //       { icon: "mdi-information-variant", econ: 'mdi-blank', active: false, title: "About", link: "/about", },
    //     ]

    // // 현장 관리자
    // } else if (payload.grade < "UT06") {
    //     list = [
    //       { icon: 'mdi-home', econ: 'mdi-blank', active: false, title: '모니터 홈', link: "/home", },
    //       { icon: 'mdi-flag-checkered', active: false, title: '농장/사업장 정보',
    //         items:  [
    //                   { link: "/farminfo/listbuildings", icon: "mdi-bank", title: "축사/건물/구역 목록" },
    //                   { link: "/farminfo/listdealcompanies", icon: "mdi-truck", title: "거래처 목록",},
    //                   { link: "/farminfo/room", icon: "mdi-view-dashboard", title: "돈방/위치 목록" },
    //                 ],
    //       },
    //       { icon: 'mdi-monitor-eye', active: false, title: '센서 상태',
    //         items:  [
    //                   { link: "/device/listsensoronoff", icon: "mdi-access-point-network", title: "센서 수신 목록" },
    //                   { link: "/device/monitorsensor", icon: "mdi-chart-line", title: "센서 수신 DATA" },
    //                   // { link: "/device/registersensor", icon: "mdi-plus-network", title: "센서 현장 등록" },
    //                 ],
    //       },
    //       { icon: 'mdi-usb', econ: 'mdi-blank', active: false, title: '장치 등록',link: "/device/registerdevice", },
    //       { icon: 'mdi-account-key', econ: 'mdi-blank', active: false, title: '내정보 확인/변경', link: "/user/myinfo", },
    //       { icon: 'mdi-brightness-7', econ: 'mdi-blank', active: false, title: '정보 설정', link: "/setinfo", },
    //       { icon: "mdi-information-variant", econ: 'mdi-blank', active: false, title: "About", link: "/about", },
    //     ]

    // // 조회자
    // } else {
    //     list = [
    //       { icon: 'mdi-home', econ: 'mdi-blank', active: false, title: '모니터 홈', link: "/home", },
    //       { icon: 'mdi-monitor-eye', active: false, title: '센서 상태',
    //         items:  [
    //                   { link: "/device/listsensoronoff", icon: "mdi-access-point-network", title: "센서 수신 목록" },
    //                   { link: "/device/monitorsensor", icon: "mdi-chart-line", title: "센서 수신 DATA" },
    //                   // { link: "/device/registersensor", icon: "mdi-plus-network", title: "센서 현장 등록" },
    //                 ],
    //       },
    //       { icon: 'mdi-usb', econ: 'mdi-blank', active: false, title: '장치 등록',link: "/device/registerdevice", },
    //       { icon: 'mdi-account-key', econ: 'mdi-blank', active: false, title: '내정보 확인/변경', link: "/user/myinfo", },
    //       { icon: 'mdi-brightness-7', econ: 'mdi-blank', active: false, title: '정보 설정', link: "/setinfo", },
    //       { icon: "mdi-information-variant", econ: 'mdi-blank', active: false, title: "About", link: "/about", },
    //     ]
    // }

    // 2022-06-18 업무팀 요청사항 반영 !xxxxxxxxx!            
    // 수퍼바이저
    // 2022-06-26 쿠키사용 안함
  //   if (payload.grade < "UT02") {
  //     list = [
  //       { icon: 'mdi-monitor-eye', econ: 'mdi-blank', active: false, title: '모니터링 요약', link: "/monitoring/summary", },
  //       { icon: 'mdi-view-list', active: false, title: '지표 조회',
  //         items:  [
  //                   { link: "/monitoring/cardroominfo", icon: "mdi-view-module", title: "간편 조회",},
  //                   { link: "/monitoring/barometer", icon: "mdi-format-list-bulleted", title: "상세 조회" },
  //                 ],
  //       },
  //       { icon: 'mdi-clipboard-text', active: false, title: '관리 일지',
  //         items:  [
  //                   { link: "/dailyrecord/event", icon: "mdi-keyboard", title: "등록",},
  //                   { link: "/dailyrecord/listevents", icon: "mdi-format-list-bulleted", title: "목록" },
  //                 ],
  //       },
  //       { icon: 'mdi-fan', econ: 'mdi-blank', active: false, title: '제어 설정',link: "/device/settingcontrol", },
  //       { icon: 'mdi-brightness-7', econ: 'mdi-blank', active: false, title: '정보 수정',link: "/setinfo", },
  //       { icon: 'mdi-account-key', econ: 'mdi-blank', active: false, title: '내정보 수정', link: "/user/myinfo", },
  //       // { icon: "mdi-information-variant", econ: 'mdi-blank', active: false, title: "About", link: "/about", },
  //     ]

  // // 시스템 관리자
  // } else if (payload.grade < "UT04") {
  //     list = [
  //       { icon: 'mdi-monitor-eye', econ: 'mdi-blank', active: false, title: '모니터링 요약', link: "/monitoring/summary", },
  //       { icon: 'mdi-view-list', active: false, title: '지표 조회',
  //         items:  [
  //                   { link: "/monitoring/cardroominfo", icon: "mdi-view-module", title: "간편 조회",},
  //                   { link: "/monitoring/barometer", icon: "mdi-format-list-bulleted", title: "상세 조회" },
  //                 ],
  //       },
  //       { icon: 'mdi-clipboard-text', active: false, title: '관리 일지',
  //         items:  [
  //                   { link: "/dailyrecord/event", icon: "mdi-keyboard", title: "등록",},
  //                   { link: "/dailyrecord/listevents", icon: "mdi-format-list-bulleted", title: "목록" },
  //                 ],
  //       },
  //       { icon: 'mdi-fan', econ: 'mdi-blank', active: false, title: '제어 설정',link: "/device/settingcontrol", },
  //       { icon: 'mdi-brightness-7', econ: 'mdi-blank', active: false, title: '정보 수정',link: "/setinfo", },
  //       { icon: 'mdi-account-key', econ: 'mdi-blank', active: false, title: '내정보 수정', link: "/user/myinfo", },
  //       // { icon: "mdi-information-variant", econ: 'mdi-blank', active: false, title: "About", link: "/about", },
  //     ]

  // // 현장 관리자
  // } else if (payload.grade < "UT06") {
  //     list = [
  //       { icon: 'mdi-monitor-eye', econ: 'mdi-blank', active: false, title: '모니터링 요약', link: "/monitoring/summary", },
  //       { icon: 'mdi-view-list', active: false, title: '지표 조회',
  //         items:  [
  //                   { link: "/monitoring/cardroominfo", icon: "mdi-view-module", title: "간편 조회",},
  //                   { link: "/monitoring/barometer", icon: "mdi-format-list-bulleted", title: "상세 조회" },
  //                 ],
  //       },
  //       { icon: 'mdi-clipboard-text', active: false, title: '관리 일지',
  //         items:  [
  //                   { link: "/dailyrecord/event", icon: "mdi-keyboard", title: "등록",},
  //                   { link: "/dailyrecord/listevents", icon: "mdi-format-list-bulleted", title: "목록" },
  //                 ],
  //       },
  //       { icon: 'mdi-fan', econ: 'mdi-blank', active: false, title: '제어 설정',link: "/device/settingcontrol", },
  //       { icon: 'mdi-account-key', econ: 'mdi-blank', active: false, title: '내정보 수정', link: "/user/myinfo", },
  //       // { icon: "mdi-information-variant", econ: 'mdi-blank', active: false, title: "About", link: "/about", },
  //     ]

  // // 조회자
  // } else {
  //     list = [
  //       { icon: 'mdi-monitor-eye', econ: 'mdi-blank', active: false, title: '모니터링 요약', link: "/monitoring/summary", },
  //       { icon: 'mdi-view-list', active: false, title: '지표 조회',
  //         items:  [
  //                   { link: "/monitoring/cardroominfo", icon: "mdi-view-module", title: "간편 조회",},
  //                   { link: "/monitoring/barometer", icon: "mdi-format-list-bulleted", title: "상세 조회" },
  //                 ],
  //       },
  //       { icon: 'mdi-clipboard-text', active: false, title: '관리 일지',
  //         items:  [
  //                   { link: "/dailyrecord/event", icon: "mdi-keyboard", title: "등록",},
  //                   { link: "/dailyrecord/listevents", icon: "mdi-format-list-bulleted", title: "목록" },
  //                 ],
  //       },
  //       { icon: 'mdi-account-key', econ: 'mdi-blank', active: false, title: '내정보 수정', link: "/user/myinfo", },
  //       // { icon: "mdi-information-variant", econ: 'mdi-blank', active: false, title: "About", link: "/about", },
  //     ]
  // }
  // VueCookies.set("menu_list" ,JSON.stringify(list),"30d")
  },
  logout(state) {

    // console.log("will remove cookie")
    Vue.axios.defaults.headers.common['authorization'] = null;
    VueCookies.remove("auth_token")
    VueCookies.remove("account")
    VueCookies.remove("name")
    VueCookies.remove("grade")
    VueCookies.remove("menu_list")


    state.isLogin = false;
    state.userInfo = USER_DATA(); // state 사용자 데이터 초기화
  },
  showAlert(state, payload) {
    state.showAlert = payload;
  },
  setAlert(state, { title, text }) {
    state.alertText = text;
    state.alertTitle = title;
  },
  // == company ==
  comboCompanies(state, payload) {
    state.companyInfo = payload;
  },
  // == farm ==
  comboBuildings(state, payload) {
    state.buildingInfo = payload;
  },
  comboFarms(state, payload) {
    state.farmInfo = payload;
  },

  use_selected_org_info(state, payload) {
    state.use_selected_org_info = payload
  },
  selected_org_info(state, payload) {
    state.selected_org_info = payload
    // console.log("TEST vuex state : ", state.selected_org_info)
  },
  resMessage(state, payload) {
    state.resMessage = payload
    // console.log("TEST vuex state : ", state.resMessage)
  },
};
export default mutations;
