import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import "@/utils/plugins/axios";
import CustomPlugin from "@/utils/plugins/customPlugin.js";
Vue.use(CustomPlugin);
import { VueMaskDirective } from 'v-mask';
Vue.directive('mask', VueMaskDirective);

Vue.config.productionTip = false

Vue.prototype.$store = store;
Vue.prototype.$getters = store.getters;
Vue.prototype.$commit = store.commit;
Vue.prototype.$dispatch = store.dispatch;


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
