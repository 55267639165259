import VueCookies from "vue-cookies";


const getters = {
  drawer(state) {
    return state.drawer;
  },
  // eslint-disable-next-line no-unused-vars
  isLogin(state) {
    let token = VueCookies.get("auth_token");
    // console.log("auth token")
    // console.log(token)
    if (token)
    {
      // console.log("isLogin true")
      state.userInfo.name = VueCookies.get("name");
      state.userInfo.grade = VueCookies.get("grade");
      state.isLogin = true;
    }
    else
    {
      // console.log("isLogin false")
      state.isLogin = false;
    }
    return state.isLogin;
  },
  userInfo(state) {
    return state.userInfo;
  },
  sessionId(state) {
    return state.sessionId;
  },
  userName(state) {
    return state.isLogin ? state.userInfo.name : "";
  },
  showAlert(state) {
    return state.showAlert;
  },
  alertTitle(state) {
    return state.alertTitle;
  },
  alertText(state) {
    return state.alertText;
  },
  // == company ==
  companyInfo(state) {
    return state.companyInfo;
  },
  // == farm ==
  farmInfo(state) {
    return state.farmInfo;
  },
  resMessage(state) {
    // console.log("state",state.resMessage)
    return state.resMessage ;
  },
};
export default getters;
