import objectProperty from "@/utils/custom/objectProperty.js";

export default {
  install(Vue) {
    Vue.mixin({
      data() {
        return {
          object: objectProperty,
        };
      },
      methods: {
        isNull(object) {
          return this.object.isNull(object);
        },
      },
    });
  },
};
