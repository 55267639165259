const USER_DATA = () => {
  return {
    account: "",
    authToken: "",
    belongcompany: "",
    belongcompany_cd: "",
    grade: "",
    name: "",
    service: "",
  };
};

const state = {
  drawer: false,
  // == user ==
  sessionId: "",
  isLogin: false,
  userInfo: USER_DATA(),

  // == company ==
  companyInfo: [],

  // == farm ==
  buildingInfo: {},
  farmInfo: {},

  // etc
  showAlert: false,
  alertTitle: "Title",
  alertText: "Text",

  // 사양 관리후 모니터링 으로 돌아온 경우 reload 처리를 위함. 
  use_selected_org_info: false,
  selected_org_info: {},

  resMessage: "",
  
};
export default state;
