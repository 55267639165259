import Vue from 'vue'
import VueRouter from 'vue-router'

const HomeCard = () => import('../views/Home.vue');
const Home = () => import('../views/Home.vue');

// const compoTest = () => import('../views/monitoring/compoTest.vue');

// const FarmMap = () => import('../views/FarmMap.vue');
const MainMenu = () => import('@/views/MainMenu.vue');
const SetInfo = () => import('@/views/SetInfo.vue');
const MtraceAgree = () => import('@/components/MtraceAgree.vue');

const HomeDash = () => import('@/views/dashboard/HomeDash.vue');


// 모니터링
const Summary = () => import('@/views/monitoring/Summary.vue');

const Barometer = () => import('@/views/monitoring/Barometer.vue');
const CardRoomInfo = () => import('@/views/monitoring/CardRoomInfo.vue');

const WattageDetail = () => import('@/views/monitoring/WattageDetail.vue');

const CardRoomTablet = () => import('@/views/monitoring/CardRoomTablet.vue');
const RoomMonitor = () => import('@/views/monitoring/RoomMonitor.vue');
const AllMonitor = () => import('@/views/monitoring/AllMonitor.vue');
const RoomTablet = () => import('@/views/monitoring/RoomTablet.vue');
// const Control = () => import('@/views/Control.vue');
// const Setup = () => import('@/views/Setup.vue');
// const Asset = () => import('@/views/Asset.vue');
// const Enter = () => import('@/views/asset/Enter.vue');
// const Modify = () => import('@/views/asset/Modify.vue');
// const Mortality = () => import('@/views/asset/Mortality.vue');
// const Move = () => import('@/views/asset/Move.vue');
// const Ship = () => import('@/views/asset/Ship.vue');
// const AssetSummary = () => import('@/views/asset/Summary.vue');

// 사용자 관련 
// 로그인 화면(회원가입,아이디찾기,비번찾기) 
const Login = () => import('@/views/user/Login.vue');
const JoinAgree = () => import('@/views/user/JoinAgree.vue');
const JoinInfo = () => import('@/views/user/JoinInfo.vue');
const FindPassword = () => import('@/views/user/FindPassword.vue');
const FindAccount = () => import("@/views/user/FindAccount.vue");

const MyInfo = () => import('@/views/user/MyInfo.vue'); 
const RegisterUser = () => import('@/views/user/RegisterUser.vue'); 

// 농장/사업장 기초정보 
const Company = () => import('@/views/farminfo/Company.vue');
const Farm = () => import('@/views/farminfo/Farm.vue');
const Building = () => import('@/views/farminfo/Building.vue');

// const Company = () => import('@/views/farminfo/Company.vue');
// const Farm = () => import('@/views/farminfo/Farm.vue');
// const Building = () => import('@/views/farminfo/Building.vue');
const Room = () => import('@/views/farminfo/Room.vue');
const DealCompany = () => import('@/views/farminfo/DealCompany.vue');
const ListDealCompanies = () => import('@/views/farminfo/ListDealCompanies.vue');

// const ListCompanies = () => import('@/views/farminfo/ListCompanies.vue');
// const ListFarms = () => import('@/views/farminfo/ListFarms.vue');
// const ListBuildings = () => import('@/views/farminfo/ListBuildings.vue');

const RegisterInfo = () => import('@/views/farminfo/RegisterInfo.vue');

// const ListSections = () => import('@/views/farminfo/ListSections.vue');
// const ListZones = () => import('@/views/farminfo/ListZones.vue');

// 디바이스
const ListSensorOnOff = () => import('@/views/device/ListSensorOnOff.vue');
// const MonitorSensor = () => import('@/views/device/MonitorSensor.vue');
// const MonitorSensor2 = () => import('@/views/device/MonitorSensor2.vue');
const RegisterDevice = () => import('@/views/device/RegisterDevice.vue');
const SettingControl = () => import('@/views/device/SettingControl.vue');
const SettingPannel = () => import('@/views/device/SettingPannel.vue');

// QR 디바이스 등등록록
const QRsmartbox = () => import('@/views/qrcode/QRsmartbox.vue');
const QRsensornode = () => import('@/views/qrcode/QRsensornode.vue');

// 돈방/위치관리일지
const Event = () => import('@/views/dailyrecord/QuickEvent.vue');
const ListEvents = () => import('@/views/dailyrecord/ListEvents.vue');
const TotalEvents = () => import('@/views/dailyrecord/TotalEvents.vue');

const DailyTransfer = () => import ('@/views/dailyrecord/DailyTransfer.vue');

// 기초 데이타
const BaseData = () => import('@/views/setup/BaseData.vue');

// 테스트 
const ComponentTest = () => import('@/views/ComponentTest.vue');
//const VueStudy = () => import('@/views/VueStudy.vue');

// 제어
const ControlSummary = () => import('@/views/control/ControlSummary.vue');
const ControlSet = () => import('@/views/control/ControlSet.vue');
// const ControlSet2 = () => import('@/views/control/ControlSet2.vue');

const ControlTable = () => import('@/views/control/ControlTable.vue');
const ControlTable2 = () => import('@/views/control/ControlTable2.vue');


// 악취
const DeodorizerHome = () => import ('@/views/deodorizer/DeodorizerHome.vue');
const DeodorizerMonitoring = () => import ('@/views/deodorizer/DeodorizerMonitoring.vue');
const DeodorizerSet = () => import ('@/views/deodorizer/DeodorizerSet.vue');
const DeodorizerDviceMonitoring = () => import ('@/views/deodorizer/DeodorizerDviceMonitoring.vue');
const DeodorizerTest = () => import ('@/views/deodorizer/DeodorizerTest.vue');

// 악취 장비 모델
const JYDeodorizer = () => import ('@/views/deodorizer/model/jy_Deodorizer.vue');
const JYDeodorizer2 = () => import ('@/views/deodorizer/model/jy_Deodorizer2.vue');

// 알림
const AlertSet = () => import ('@/views/alert/AlertSet.vue');
const AlertSet2 = () => import ('@/views/alert/AlertSet2.vue');
const AlertPersonalSet = () => import ('@/views/alert/AlertPersonalSet.vue');
const AlertDataTest = () => import ('@/views/alert/AlertDataTest.vue');

// MAP 테스트 
// const KakaoMap = () => import("@/views//KakaoMap.vue");

//esg
const MonitorEsg = () => import ('@/views/esg/MonitorEsg.vue');
const MonitorEsg2 = () => import ('@/views/esg/MonitorEsg2.vue');
const SimulationEsg = () => import ('@/views/esg/SimulationEsg.vue');

import VueCookies from "vue-cookies";

Vue.use(VueRouter)

const routes = [
  {
    path: "/site",
    name: "MainMenu",
    component: MainMenu,
    beforeEnter: redirectTo("isLogin", "/user/login"),
  },
  {
    path: "/",
    name: "/",
    component: Home,
    beforeEnter: redirectTo("isLogin", "/about"),
  },

  // {
  //   path: "/compo",
  //   name: "compoTest",
  //   component: compoTest,
  //   beforeEnter: redirectTo("isLogin", "/about"),
  // },


  {
    path: '/user/login',
    name: 'Login',
    component: Login,
    beforeEnter: (to, from, next) => {
      let token = VueCookies.get("auth_token");
      // console.log("token",token);
      if (token)
        next('/')
      else
        next()
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    beforeEnter: redirectTo("isLogin", "/"),
  },
  {
    path: '/homecard',
    name: 'HomeCard',
    component: HomeCard,
    beforeEnter: redirectTo("isLogin", "/"),
  },


  // 로그인 관련 -->
  {
    path: '/user/joinagree',
    name: 'JoinAgree',
    component: JoinAgree
  },
  {
    path: '/user/joininfo',
    name: 'JoinInfo',
    component: JoinInfo
  },
  {
    path: '/user/findid',
    name: 'FindAccount',
    component: FindAccount
  },
  {
    path: '/user/findpassword',
    name: 'FindPassword',
    component: FindPassword
  },
  
  // --> 돈방/위치 모니터 링   
  {
    path: '/monitoring/summary',
    name: 'Summary',
    component: Summary,
    beforeEnter: redirectTo("isLogin", "/user/login"),
  },
  {
    path: '/monitoring/barometer',
    name: 'Barometer',
    component: Barometer,
    beforeEnter: redirectTo("isLogin", "/user/login"),
  },
  {
    path: '/monitoring/cardroominfo',
    name: 'CardRoomInfo',
    component: CardRoomInfo,
    beforeEnter: redirectTo("isLogin", "/user/login"),
  },

  {
    path: '/monitoring/wattagedetail',
    name: 'WattageDetail',
    component: WattageDetail,
    beforeEnter: redirectTo("isLogin", "/user/login"),
  },

  {
    path: '/monitoring/cardroomtablet',
    name: 'CardRoomTablet',
    component: CardRoomTablet,
    beforeEnter: redirectTo("isLogin", "/user/login"),
  },

  {
    path: '/monitoring/roommonitor',
    name: 'RoomMonitor',
    component: RoomMonitor,
    beforeEnter: redirectTo("isLogin", "/user/login"),
  },
  {
    path: '/monitoring/allmonitor',
    name: 'AllMonitor',
    component: AllMonitor,
    beforeEnter: redirectTo("isLogin", "/user/login"),
  },

  {
    path: '/monitoring/roomtablet',
    name: 'RoomTablet',
    component: RoomTablet,
    beforeEnter: redirectTo("isLogin", "/user/login"),
  },
  // {
  //   path: '/asset',
  //   name: 'Asset',
  //   component: Asset
  // },
  // {
  //   path: '/asset/enter',
  //   name: 'Enter',
  //   component: Enter
  // },
  // {
  //   path: '/asset/modify',
  //   name: 'Modify',
  //   component: Modify
  // },
  // {
  //   path: '/asset/mortality',
  //   name: 'Mortality',
  //   component: Mortality
  // },
  // {
  //   path: '/asset/move',
  //   name: 'Move',
  //   component: Move
  // },
  // {
  //   path: '/asset/ship',
  //   name: 'Ship',
  //   component: Ship
  // },
  // {
  //   path: '/asset/summary',
  //   name: 'AssetSummary',
  //   component: AssetSummary
  // },
  // {
  //   path: '/control',
  //   name: 'Control',
  //   component: Control
  // },

  // 기본 정보 (사용자,농장/사업장,거래처 등) -->
  {
    path: '/setinfo',
    name: 'SetInfo',
    component: SetInfo
  },
  {
    path: '/mtraceAgree',
    name: 'MtraceAgree',
    component: MtraceAgree
  },
  {
    path: '/user/myinfo',
    name: 'MyInfo',
    component: MyInfo
  },
  {
    path: '/user/registeruser',
    name: 'RegisterUser',
    component: RegisterUser,
    beforeEnter: redirectTo("isLogin", "/user/login"),
  },
  // {
  //   path: '/farminfo/listcompanies',
  //   name: 'ListCompanies',
  //   component: ListCompanies,
  //   beforeEnter: redirectTo("isLogin", "/user/login"),
  // },
  // {
  //   path: '/farminfo/company',
  //   name: 'Company',
  //   component: Company,
  //   beforeEnter: redirectTo("isLogin", "/user/login"),
  // },
  // {
  //   path: '/farminfo/listfarms',
  //   name: 'ListFarms',
  //   component: ListFarms,
  //   beforeEnter: redirectTo("isLogin", "/user/login"),
  // },
  // {
  //   path: '/farminfo/farm',
  //   name: 'Farm',
  //   component: Farm,
  //   beforeEnter: redirectTo("isLogin", "/user/login"),
  // },
  // {
  //   path: '/farminfo/listbuildings',
  //   name: 'ListBuildings',
  //   component: ListBuildings,
  //   beforeEnter: redirectTo("isLogin", "/user/login"),
  // },
  // {
  //   path: '/farminfo/building',
  //   name: 'Building',
  //   component: Building,
  //   beforeEnter: redirectTo("isLogin", "/user/login"),
  // },
  // {
  //   path: '/farminfo/room',
  //   name: 'Room',
  //   component: Room,
  //   beforeEnter: redirectTo("isLogin", "/user/login"),
  // },

  {
    path: '/homedash',
    name: 'HomeDash',
    component: HomeDash
  },

  {
    path: '/farminfo/registerinfo',
    name: 'RegisterInfo',
    component: RegisterInfo,
    props: true,
    beforeEnter: redirectTo("isLogin", "/user/login"),
    children: [
      {
        path: "company",
        name: "company",
        component: Company
      },
      {
        path: "farm",
        name: "farm",
        component: Farm
      },
      {
        path: "building",
        name: "building",
        component: Building
      },
      {
        path: "room",
        name: "room",
        component: Room
      },
    ]

  },
  {
    path: '/farminfo/dealcompany',
    name: 'DealCompany',
    component: DealCompany,
    beforeEnter: redirectTo("isLogin", "/user/login"),
  },
  // {
  //   path: '/farminfo/listsections',
  //   name: 'ListSectons',
  //   component: ListSections,
  //   beforeEnter: redirectTo("isLogin", "/user/login"),
  // },
  // {
  //   path: '/farminfo/listzones',
  //   name: 'ListZones',
  //   component: ListZones,
  //   beforeEnter: redirectTo("isLogin", "/user/login"),
  // },
  {
    path: '/farminfo/listdealcompanies',
    name: 'ListDealCompanies',
    component: ListDealCompanies,
    beforeEnter: redirectTo("isLogin", "/user/login"),
  },
  
  // --> 디바이스 
  {
    path: '/device/listsensoronoff',
    name: 'ListSensorOnOff',
    component: ListSensorOnOff,
    beforeEnter: redirectTo("isLogin", "/user/login"),
  },
  // {
  //   path: '/device/monitorsensor',
  //   name: 'MonitorSensor',
  //   component: MonitorSensor,
  //   beforeEnter: redirectTo("isLogin", "/user/login"),
  // },
  // {
  //   path: '/device/monitorsensor2',
  //   name: 'MonitorSensor2',
  //   component: MonitorSensor2,
  //   beforeEnter: redirectTo("isLogin", "/user/login"),
  // },
  {
    path: '/device/registerdevice',
    name: 'RegisterDevice',
    component: RegisterDevice,
    beforeEnter: redirectTo("isLogin", "/user/login"),
  },
  {
    path: '/device/settingcontrol',
    name: 'SettingControl',
    component: SettingControl,
    beforeEnter: redirectTo("isLogin", "/user/login"),
  },
  {
    path: '/device/settingpannel',
    name: 'SettingPannel',
    component: SettingPannel,
    beforeEnter: redirectTo("isLogin", "/user/login"),
  },

  // --> QR 디바이스 등록 
  {
    path: '/qrcode/qrsmartbox',
    name: 'QRsmartbox',
    component: QRsmartbox,
    beforeEnter: redirectTo("isLogin", "/user/login"),
  },
  {
    path: '/qrcode/qrsensornode',
    name: 'QRsensornode',
    component: QRsensornode,
    beforeEnter: redirectTo("isLogin", "/user/login"),
  },

  // --> 돈방/위치관리일지 
  {
    path: '/dailyrecord/event',
    name: 'Event',
    component: Event,
    beforeEnter: redirectTo("isLogin", "/user/login"),
  },
  {
    path: '/dailyrecord/listevents',
    name: 'ListEvents',
    component: ListEvents,
    beforeEnter: redirectTo("isLogin", "/user/login"),
  },
  {
    path: '/dailyrecord/totalevents',
    name: 'TotalEvents',
    component: TotalEvents,
    beforeEnter: redirectTo("isLogin", "/user/login"),
  },

  {
    path: '/dailyrecord/dailytransfer',
    name: 'DailyTransfer',
    component: DailyTransfer,
    beforeEnter: redirectTo("isLogin", "/user/login"),
  },
  
  // --> 제어
  {
    path: '/control/controlsummary',
    name: 'ControlSummary',
    component: ControlSummary,
    beforeEnter: redirectTo("isLogin", "/user/login"),
  },

  {
    path: '/control/controlset',
    name: 'ControlSet',
    component: ControlSet,
    beforeEnter: redirectTo("isLogin", "/user/login"),
  },
  // {
  //   path: '/control/controlset2',
  //   name: 'ControlSet2',
  //   component: ControlSet2,
  //   beforeEnter: redirectTo("isLogin", "/user/login"),
  // },

  {
    path: '/control/controltable',
    name: 'ControlTable',
    component: ControlTable,
    beforeEnter: redirectTo("isLogin", "/user/login"),
  },
  {
    path: '/control/controltable2',
    name: 'ControlTable2',
    component: ControlTable2,
    beforeEnter: redirectTo("isLogin", "/user/login"),
  },
  // --> 악취
  {
    path: '/deodorizer/deodorizerHome',
    name: 'DeodorizerHome',
    component: DeodorizerHome,
    beforeEnter: redirectTo("isLogin", "/user/login"),
  },
  {
    path: '/deodorizer/deodorizerMonitoring',
    name: 'DeodorizerMonitoring',
    component: DeodorizerMonitoring,
    beforeEnter: redirectTo("isLogin", "/user/login"),
  },
  {
    path: '/deodorizer/deodorizerset',
    name: 'DeodorizerSet',
    component: DeodorizerSet,
    beforeEnter: redirectTo("isLogin", "/user/login"),
  },
  {
    path: '/deodorizer/deodorizerdviceMonitoring',
    name: 'DeodorizerDviceMonitoring',
    component: DeodorizerDviceMonitoring,
    beforeEnter: redirectTo("isLogin", "/user/login"),
  },
  {
    path: '/deodorizer/deodorizertest',
    name: 'DeodorizerTest',
    component: DeodorizerTest,
    beforeEnter: redirectTo("isLogin", "/user/login"),
  },
  // --> 악취장비모델
  {
    path: '/deodorizer/jydeodorizer',
    name: 'JYDeodorizer',
    component: JYDeodorizer,
    beforeEnter: redirectTo("isLogin", "/user/login"),
  },
  {
    path: '/deodorizer/jydeodorizer2',
    name: 'JYDeodorizer2',
    component: JYDeodorizer2,
    beforeEnter: redirectTo("isLogin", "/user/login"),
  },
  // --> 알람
  {
    path: '/alert/AlertSet',
    name: 'AlertSet',
    component: AlertSet,
    beforeEnter: redirectTo("isLogin", "/user/login"),
  },
  {
    path: '/alert/AlertSet2',
    name: 'AlertSet2',
    component: AlertSet2,
    beforeEnter: redirectTo("isLogin", "/user/login"),
  },
  {
    path: '/alert/AlertPersonalSet',
    name: 'AlertPersonalSet',
    component: AlertPersonalSet,
    beforeEnter: redirectTo("isLogin", "/user/login"),
  },
  {
    path: '/alert/AlertDataTest',
    name: 'AlertDataTest',
    component: AlertDataTest,
    beforeEnter: redirectTo("isLogin", "/user/login"),
  },
  // --> 기초 데이타 
  {
    path: '/setup/basedata',
    name: 'BaseData',
    component: BaseData,
    beforeEnter: redirectTo("isLogin", "/user/login"),
  },
  // --> esg
  {
    path: '/esg/monitorEsg',
    name: 'MonitorEsg',
    component: MonitorEsg,
    beforeEnter: redirectTo("isLogin", "/user/login"),
  },
  {
    path: '/esg/monitorEsg2',
    name: 'MonitorEsg2',
    component: MonitorEsg2,
    beforeEnter: redirectTo("isLogin", "/user/login"),
  },
  {
    path: '/esg/SimulationEsg',
    name: 'SimulationEsg',
    component: SimulationEsg,
    beforeEnter: redirectTo("isLogin", "/user/login"),
  },
  // --> SYSTEM , TEST 등
  {
    path: '/comp_test',
    name: 'ComponentTest',
    component: ComponentTest
  },
  {
    path: '/farmmap',
    name: 'FarmMap',
    component: () => import('../views/FarmMap.vue')
  },
  {
    path: '/sensormap',
    name: 'SensorMap',
    component: () => import('../views/Map/SensorMap.vue')
  },
  {
    path: '/sensormap2',
    name: 'SensorMap2',
    component: () => import('../views/Map/SensorMap2.vue')
  },
/*
  {
    path: '/study',
    name: 'VueStudy',
    component: VueStudy
  },
*/
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/About.vue')
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  changeBgColor(to.path);
  if (!hasQueryParams(to) && hasQueryParams(from)) {
    next({ path: to.path, query: from.query });
  } else {
    next();
  }
});

function hasQueryParams(route) {
  return !!Object.keys(route.query).length;
}

function changeBgColor(path) {
  switch (path) {
    case "/":
    case "/findAccount":
    case "/config":
      document.body.style.background = "white";
      // document.body.style.background = "black";
      break;
    case "/login":
      document.body.style.background = "#3a4f3f";
      break;
    default:
      document.body.style.background = "#f8f8f8";
      break;
  }
}

function redirectTo(getterName, redirectPath) {
  return function(to, from, next) {
    // console.log(
    //   "nevigation guard 검증 : getterName : " +
    //     getterName +
    //     ", redirectPath : " +
    //     redirectPath
    // );
    // if (store.getters[getterName]) {
    //   return next();

    let token = VueCookies.get("auth_token");
    // console.log("router -------  auth token")
    // console.log(token)
    if (token) {
      return next()

    } else {
      const redirect = { path: redirectPath };
      if (hasQueryParams(to)) {
        redirect.query = to.query;
      }
      changeBgColor(redirectPath);
      return next(redirect);
    }
  };
}

export default router
