// const config = {
//   mode : "dev"
// }
const SERVER =  
  document.domain.includes('pigro') // 실서비스 
  ?
  "https://api.onespring.co.kr/"
  // "http://pigro.onespring.co.kr:4011/"
  :
  "https://dev-api.onespring.co.kr/"  // 개발 서버 
  //  "https://api.onespring.co.kr/"
  // "http://localhost:4001/"
  // "http://pigro.bitzflex.com:4001/"
// console.log("SERVER url",SERVER);      

const ROOT_PATH = "api/v2020/";

export const URLS = {
  user: {
    login: SERVER + ROOT_PATH + "user/login",
    logout: SERVER + ROOT_PATH + "user/logout",
    pageCount: SERVER + ROOT_PATH + "user/pageCount",
    findID: SERVER + ROOT_PATH + "user/findID",
    tempPassword: SERVER + ROOT_PATH + "user/tempPassword",
    changePassword: SERVER + ROOT_PATH + "user/changePassword",
    accountCheck: SERVER + ROOT_PATH + "user/accountCheck",
    insertUser: SERVER + ROOT_PATH + "user/insertUser",
    joinUser: SERVER + ROOT_PATH + "user/joinUser",
    searchCompanies: SERVER + ROOT_PATH + "user/searchCompanies",
    updateUser: SERVER + ROOT_PATH + "user/updateUser",
    updateUserFree: SERVER + ROOT_PATH + "user/updateUserFree",
    infoUser: SERVER + ROOT_PATH + "user/infoUser",
    getUserType: SERVER + ROOT_PATH + "user/getUserType",
    listUsers: SERVER + ROOT_PATH + "user/listUsers",
    insertUsersControl: SERVER + ROOT_PATH + "user/insertUsersControl",
    deleteUsersControl: SERVER + ROOT_PATH + "user/deleteUsersControl",
    treeManagermentCompanies: SERVER + ROOT_PATH + "user/treeManagermentCompanies",
    listAccesableServices : SERVER + ROOT_PATH + "user/listAccesableServices",
  },
  farm: {
    insertCompany: SERVER + ROOT_PATH + "farm/insertCompany",
    updateCompany: SERVER + ROOT_PATH + "farm/updateCompany",
    updateCompanyFree: SERVER + ROOT_PATH + "farm/updateCompanyFree",
    infoCompany: SERVER + ROOT_PATH + "farm/infoCompany",
    comboCompanies: SERVER + ROOT_PATH + "farm/comboCompanies",
    listCompanies: SERVER + ROOT_PATH + "farm/listCompanies",
    insertFarm: SERVER + ROOT_PATH + "farm/insertFarm",
    updateFarm: SERVER + ROOT_PATH + "farm/updateFarm",
    updateFarmFree: SERVER + ROOT_PATH + "farm/updateFarmFree",
    infoFarm: SERVER + ROOT_PATH + "farm/infoFarm",
    comboFarms: SERVER + ROOT_PATH + "farm/comboFarms",
    listFarms: SERVER + ROOT_PATH + "farm/listFarms",
    listFarmsForMap: SERVER + ROOT_PATH + "farm/listFarmsForMap",
    insertBuildingOne: SERVER + ROOT_PATH + "farm/insertBuildingOne",
    updateBuildingTwo: SERVER + ROOT_PATH + "farm/updateBuildingTwo",
    updateBuildingFree: SERVER + ROOT_PATH + "farm/updateBuildingFree",
    infoBuilding: SERVER + ROOT_PATH + "farm/infoBuilding",
    comboBuildings: SERVER + ROOT_PATH + "farm/comboBuildings",
    listBuildings: SERVER + ROOT_PATH + "farm/listBuildings",
    insertRoom: SERVER + ROOT_PATH + "farm/insertRoom",
    saveRooms: SERVER + ROOT_PATH + "farm/saveRooms",
    updateRoom: SERVER + ROOT_PATH + "farm/updateRoom",
    updateRoomFree: SERVER + ROOT_PATH + "farm/updateRoomFree",
    infoRoom: SERVER + ROOT_PATH + "farm/infoRoom",
    infoRooms: SERVER + ROOT_PATH + "farm/infoRooms",
    listRooms: SERVER + ROOT_PATH + "farm/listRooms",
    comboRooms: SERVER + ROOT_PATH + "farm/comboRooms",
    listSensorsInFarmForMap: SERVER + ROOT_PATH + "farm/listSensorsInFarmForMap",

    // numberOfRooms: SERVER + ROOT_PATH + "farm/numberOfRooms",
    // insertSection: SERVER + ROOT_PATH + "farm/insertSection",
    // saveSections: SERVER + ROOT_PATH + "farm/saveSections",
    // updateSection: SERVER + ROOT_PATH + "farm/updateSection",
    // updateSectionFree: SERVER + ROOT_PATH + "farm/updateSectionFree",
    // infoSections: SERVER + ROOT_PATH + "farm/infoSections",
    // comboSections: SERVER + ROOT_PATH + "farm/comboSections",
    // insertZone: SERVER + ROOT_PATH + "farm/insertZone",
    // saveZones: SERVER + ROOT_PATH + "farm/saveZones",
    // updateZone: SERVER + ROOT_PATH + "farm/updateZone",
    // updateZoneFree: SERVER + ROOT_PATH + "farm/updateZoneFree",
    // infoZones: SERVER + ROOT_PATH + "farm/infoZones",
    // comboZones: SERVER + ROOT_PATH + "farm/comboZones",
  },
  setup: {
    insertDealCompany: SERVER + ROOT_PATH + "setup/insertDealCompany",
    updateDealCompany: SERVER + ROOT_PATH + "setup/updateDealCompany",
    infoDealCompany: SERVER + ROOT_PATH + "setup/infoDealCompany",
    comboDealCompanies: SERVER + ROOT_PATH + "setup/comboDealCompanies",
    listDealCompanies: SERVER + ROOT_PATH + "setup/listDealCompanies",
    listWeightPerDay: SERVER + ROOT_PATH + "system/listWeightPerDay",
  },
  event: {
    pigcntInSection: SERVER + ROOT_PATH + "event/pigcntInSection",
    saveEnterQuick: SERVER + ROOT_PATH + "event/saveEnterQuick",
    // saveEnterInside: SERVER + ROOT_PATH + "event/saveEnterInside",
    // saveEnterOutside: SERVER + ROOT_PATH + "event/saveEnterOutside",
    saveTransferQuick: SERVER + ROOT_PATH + "event/saveTransferQuick",
    // saveTransferInside: SERVER + ROOT_PATH + "event/saveTransferInside",
    // saveTransferOutside: SERVER + ROOT_PATH + "event/saveTransferOutside",
    saveSaleQuick: SERVER + ROOT_PATH + "event/saveSaleQuick",
    // saveSaleButcher: SERVER + ROOT_PATH + "event/saveSaleButcher",
    // saveSaleConsignment: SERVER + ROOT_PATH + "event/saveSaleConsignment",
    saveDeathQuick: SERVER + ROOT_PATH + "event/saveDeathQuick",
    // saveDeath: SERVER + ROOT_PATH + "event/saveDeath",
    infoEvent: SERVER + ROOT_PATH + "event/infoEvent",
    deleteEvent: SERVER + ROOT_PATH + "event/deleteEvent",
    listEventMemos: SERVER + ROOT_PATH + "event/listEventMemos",
    roomDailyCountByEvent: SERVER + ROOT_PATH + "event/roomDailyCountByEvent",
    // comboDeathCause: SERVER + ROOT_PATH + "event/comboDeathCause",
  },
  schedule: {
    listFarmEvent: SERVER + ROOT_PATH + "schedule/listFarmEvent",
    // listGetoutSchedule: SERVER + ROOT_PATH + "schedule/listGetoutSchedule",
  },
  device: {
    listSensorsOnOff: SERVER + ROOT_PATH + "device/listSensorsOnOff",
    comboSensors: SERVER + ROOT_PATH + "device/comboSensors",
    listSensors: SERVER + ROOT_PATH + "device/listSensors",
    listSensorsInUnit: SERVER + ROOT_PATH + "device/listSensorsInUnit",
    infoSensorByID: SERVER + ROOT_PATH + "device/infoSensorByID",
    infoSensorByMac: SERVER + ROOT_PATH + "device/infoSensorByMac",
    insertSensor: SERVER + ROOT_PATH + "device/insertSensor",
    deleteSensor: SERVER + ROOT_PATH + "device/deleteSensor",
    updateSensor: SERVER + ROOT_PATH + "device/updateSensor",
    updateSensorFree: SERVER + ROOT_PATH + "device/updateSensorFree",

    insertController: SERVER + ROOT_PATH + "device/insertController",
    updateController: SERVER + ROOT_PATH + "device/updateController",
    infoController: SERVER + ROOT_PATH + "device/infoController",
    deleteController: SERVER + ROOT_PATH + "device/deleteController",
    listControllers: SERVER + ROOT_PATH + "device/listControllers",
    listControllerModels: SERVER + ROOT_PATH + "device/listControllerModels",
    listFanModels: SERVER + ROOT_PATH + "device/listFanModels",
    listControllerSetting: SERVER + ROOT_PATH + "device/listControllerSetting",
    getNowControllerValue: SERVER + ROOT_PATH + "device/getNowControllerValue",

    insertSmartbox: SERVER + ROOT_PATH + "device/insertSmartbox",
    updateSmartbox: SERVER + ROOT_PATH + "device/updateSmartbox",
    infoSmartbox: SERVER + ROOT_PATH + "device/infoSmartbox",
    deleteSmartbox: SERVER + ROOT_PATH + "device/deleteSmartbox",
    listSmartboxs: SERVER + ROOT_PATH + "device/listSmartboxs",
    listSmartboxModels: SERVER + ROOT_PATH + "device/listSmartboxModels",
    listSmart_mac: SERVER + ROOT_PATH + "device/listSmart_mac",

    deleteDevice: SERVER + ROOT_PATH + "device/deleteDevice",
    comboData_names: SERVER + ROOT_PATH + "device/comboData_names",

    comboPLC: SERVER + ROOT_PATH + "device/comboPLC",
    insertDeodorizer : SERVER + ROOT_PATH + "device/insertDeodorizer",
    updateDeodorizer : SERVER + ROOT_PATH + "device/updateDeodorizer",
    deleteDeodorizer : SERVER + ROOT_PATH + "device/deleteDeodorizer",
    listDeodorizers : SERVER + ROOT_PATH + "device/listDeodorizers",
    infoDeodorizer : SERVER + ROOT_PATH + "device/infoDeodorizer",
    listDeodorizerModels  : SERVER + ROOT_PATH + "device/listDeodorizerModels",
    comboSensorData: SERVER + ROOT_PATH + "device/comboSensorData",
    insertDeodorizerControlDevice: SERVER + ROOT_PATH + "device/insertDeodorizerControlDevice",
    updateDeodorizerControlDevice: SERVER + ROOT_PATH + "device/updateDeodorizerControlDevice",
    listDeodorizerControlDevices: SERVER + ROOT_PATH + "device/listDeodorizerControlDevices",
    infoDeodorizerControlDevice: SERVER + ROOT_PATH + "device/infoDeodorizerControlDevice",
    listDeodorizerControlDeviceModels: SERVER + ROOT_PATH + "device/listDeodorizerControlDeviceModels",
    comboDeodorizerControlDevices: SERVER + ROOT_PATH + "device/comboDeodorizerControlDevices",
    treeDeodorizerControlDevices: SERVER + ROOT_PATH + "device/treeDeodorizerControlDevices",
    treeDeodorizerDviceMonitoring: SERVER + ROOT_PATH + "device/treeDeodorizerDviceMonitoring",

  },
  control: {
    listMonitoringControllers: SERVER + ROOT_PATH + "control/listMonitoringControllers",
    infoDetailRoomControllers: SERVER + ROOT_PATH + "control/infoDetailRoomControllers",
    listSettingHistories: SERVER + ROOT_PATH + "control/listSettingHistories",
    infoSettingHistory: SERVER + ROOT_PATH + "control/infoSettingHistory",
    saveControllerSetting: SERVER + ROOT_PATH + "control/saveControllerSetting",
    stopRemoteControl: SERVER + ROOT_PATH + "control/stopRemoteControl",
    saveSeasonRange: SERVER + ROOT_PATH + "control/saveSeasonRange",
    deleteSeasonRange: SERVER + ROOT_PATH + "control/deleteSeasonRange",
    comboSeasonRanges: SERVER + ROOT_PATH + "control/comboSeasonRanges",
    listSeasonRanges: SERVER + ROOT_PATH + "control/listSeasonRanges",
    infoSeasonRange: SERVER + ROOT_PATH + "control/infoSeasonRange",
    saveWeightRange: SERVER + ROOT_PATH + "control/saveWeightRange",
    deleteWeightRange: SERVER + ROOT_PATH + "control/deleteWeightRange",
    comboWeightRanges: SERVER + ROOT_PATH + "control/comboWeightRanges",
    listWeightRanges: SERVER + ROOT_PATH + "control/listWeightRanges",
    infoWeightRange: SERVER + ROOT_PATH + "control/infoWeightRange",
    saveControlRangeSettings: SERVER + ROOT_PATH + "control/saveControlRangeSettings",
    deleteControlRangeSettings: SERVER + ROOT_PATH + "control/deleteControlRangeSettings",
    listControlRangeSettings: SERVER + ROOT_PATH + "control/listControlRangeSettings",
    saveFanControlPreset: SERVER + ROOT_PATH + "control/saveFanControlPreset",
    loadFanControlPreset: SERVER + ROOT_PATH + "control/loadFanControlPreset",
    comboFanControlPresets: SERVER + ROOT_PATH + "control/comboFanControlPresets",
    confirmFanControlPreset: SERVER + ROOT_PATH + "control/confirmFanControlPreset",


    infoDeodorizerSetting: SERVER + ROOT_PATH + "control/infoDeodorizerSetting",
    listMonitorDeodorizers: SERVER + ROOT_PATH + "control/listMonitorDeodorizers",
    saveDeodorizerSetting: SERVER + ROOT_PATH + "control/saveDeodorizerSetting",
    setStatusOfDeodorizer: SERVER + ROOT_PATH + "control/setStatusOfDeodorizer",
    deodorizerOnLines: SERVER + ROOT_PATH + "control/deodorizerOnLines",
    deodorizerOnAllLines: SERVER + ROOT_PATH + "control/deodorizerOnAllLines",
    deodorizerOffAllLines: SERVER + ROOT_PATH + "control/deodorizerOffAllLines",
    deodorizerAutoAllLines: SERVER + ROOT_PATH + "control/deodorizerAutoAllLines",
    deodorizerOffLines: SERVER + ROOT_PATH + "control/deodorizerOffLines",
    deodorizerAutoLines: SERVER + ROOT_PATH + "control/deodorizerAutoLines",
    deodorizerManualLines: SERVER + ROOT_PATH + "control/deodorizerManualLines",
  },  
  monitor: {
    buildingSummaryView: SERVER + ROOT_PATH + "monitor/buildingSummaryView",
    farmSummaryView: SERVER + ROOT_PATH + "monitor/farmSummaryView",
    roomDailyView: SERVER + ROOT_PATH + "monitor/roomDailyView",
    room24hoursView: SERVER + ROOT_PATH + "monitor/room24hoursView",
    roomRawDataView: SERVER + ROOT_PATH + "monitor/roomRawDataView",
    monitoringSummary: SERVER + ROOT_PATH + "monitor/monitoringSummary",
    monitoringWeather: SERVER + ROOT_PATH + "monitor/monitoringWeather",
    summaryPigInfo: SERVER + ROOT_PATH + "monitor/summaryPigInfo",
    listFarmKepcoData: SERVER + ROOT_PATH + "monitor/listFarmKepcoData",
    kepcoDataDailyView: SERVER + ROOT_PATH + "monitor/kepcoDataDailyView",
    kepcoDataMonthlyView: SERVER + ROOT_PATH + "monitor/kepcoDataMonthlyView",
    listTimelyKepcoData: SERVER + ROOT_PATH + "monitor/listTimelyKepcoData",
    weatherForDeodorizer: SERVER + ROOT_PATH + "monitor/weatherForDeodorizer",
    roomMonitoringSummary: SERVER + ROOT_PATH + "monitor/roomMonitoringSummary",
    monitoringAllRooms: SERVER + ROOT_PATH + "monitor/monitoringAllRooms",
  },
  esg:{
    comboEsgFarms: SERVER + ROOT_PATH + "esg/comboEsgFarms",
    monitoringEsgValueOfFarm: SERVER + ROOT_PATH + "esg/monitoringEsgValueOfFarm",
    monitoringEsgValueOfFarm2: SERVER + ROOT_PATH + "esg/monitoringEsgValueOfFarm2",
  },
  fog:{
    get: SERVER + ROOT_PATH + "fog/get",
    set: SERVER + ROOT_PATH + "fog/set",
  },
  alert:{
    listAlertSetupOfFarm: SERVER + ROOT_PATH + "alert/listAlertSetupOfFarm",
    saveAlertSetupOfFarm: SERVER + ROOT_PATH + "alert/saveAlertSetupOfFarm",
    saveAlertSetupOfRoom: SERVER + ROOT_PATH + "alert/saveAlertSetupOfRoom",
    savePersonalChoicedAlerts: SERVER + ROOT_PATH + "alert/savePersonalChoicedAlerts",
    infoPersonalChoicedAlerts: SERVER + ROOT_PATH + "alert/infoPersonalChoicedAlerts",
  },
  alert_test: {
    check_all: SERVER + "alert_test/check_all",
    check_no_data: SERVER + "alert_test/check_no_data",
    check_range: SERVER + "alert_test/check_range",
    check_diff: SERVER + "alert_test/check_diff",
    clear_sms_wait: SERVER +  "alert_test/clear_sms_wait",
    set_test_data: SERVER + "alert_test/set_test_data",
    clear_test_data: SERVER + "alert_test/clear_test_data",
  },
  pigcount: {
    roomSummary: SERVER + ROOT_PATH + "pigcount/roomSummary",
  },
  all: {
    baseCodeList: SERVER + ROOT_PATH + "all/baseCodeList",
  },
  test: {
    testParams : SERVER + ROOT_PATH + "test/testParams" ,
    goodLuck : SERVER + ROOT_PATH + "test/goodLuck" ,    
  },
  combo: {
    // getComboList 테스트
    // getComboList: SERVER + ROOT_PATH + "combo/getComboList",
  },
  redis: {
    lastValue: SERVER + ROOT_PATH + "redis/lv",
  },
  mongo: {
    trend: SERVER + ROOT_PATH + "mongo/trend",
  },
};

export default URLS;
