import Vue from "vue";
import Vuex from "vuex";

// import user from "@/store/user/module.js";
// import company from "@/store/company/module.js";
// import sensor from "@/store/sensor/module.js";
// import etc from "@/store/etc/module.js";

import actions from "@/store/actions.js";
import getters from "@/store/getters.js";
import mutations from "@/store/mutations.js";
import state from "@/store/state.js";

Vue.use(Vuex);

export default new Vuex.Store({
  actions: {...actions},
  getters: {...getters},
  mutations: {...mutations},
  state: {...state},

  // ---------------------   하위 모듈들
  // modules: {
  //   user,
  //   company,
  //   sensor,
  //   etc,
  // },
});
