<template>
  <v-app>
    <v-app-bar app color="rgb(58,79,63)" dark flat height="45" style="z-index:99" v-if="$route.path !== '/monitoring/roommonitor' && $route.path !== '/monitoring/allmonitor' &&  $route.path !== '/mtraceagree' && $route.path !== '/homedash' ">
        <button style="margin-top: 4px; margin-right: 1px; height: 36px; width: 36" >
          <v-img
            alt="onespring Logo"
            class="shrink"
            contain
            :src="$getters.isLogin ? icon_home_white : logo_white"
            transition="scale-transition"
            width="23"
            @click="$getters.isLogin && $route.path !== '/homecard/' ? $router.push('/homecard/') : ''"
            @dblclick="$getters.isLogin && $getters.userInfo.grade < 'UT04' ? $router.push('/site') : ''"
          />
        </button>
        <!-- <v-icon
          medium
          v-show="$getters.isLogin"
          @click="$getters.isLogin && $route.path !== '/FarmMap' ? $router.push('/FarmMap') : '#'"
        >
          mdi-map-marker-radius
        </v-icon> -->
        <span class="ml-2 page_name">{{getPageName(this.$route.name) }}</span>
        <v-spacer></v-spacer>

        <v-icon 
          size="16" class="mr-1" 
          @click="certify()" 
          v-show="this.$getters.isLogin && (this.$getters.userInfo.grade == 'UT03'||this.$getters.userInfo.grade == 'UT01' )"
          > 
          mdi-information-outline 
        </v-icon>
        <a id="user_name" class="user_name" v-show="this.$getters.userName" href="javascript:void(0);" @click="myInfo()">
          {{ this.$getters.userName }}님
        </a>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <button style="margin-left: 3px; height: 35px; width: 35" 
              v-show="$route.path !== '/user/login' &&
                      !$route.path.includes('/join') &&
                      !$route.path.includes('/user/findid') &&
                      !$route.path.includes('/user/findpassword')"
              v-bind="attrs"
              v-on="on"
            >
              <v-img
                alt="로그인"
                location="bottom"
                v-show="!$getters.isLogin" 
                :src="icon_login"
                transition="scale-transition"
                height="30" width="30"
                @click="login()"
              />
              
              <v-img
                v-show="$getters.isLogin" 
                alt="로그아웃"
                location="bottom"
                :src="icon_logout"
                transition="scale-transition"
                height="30" width="30"
                @click="logOut()"
              />
            </button>
          </template>
          <span> {{ loginOutText }} </span>
        </v-tooltip> 

      <v-app-bar-nav-icon @click.stop="drawer_ = !drawer_" @click="refresh_menu">
        <img src="@/assets/navi_menu.svg" alt="메뉴" />
      </v-app-bar-nav-icon>
    </v-app-bar>

    <v-navigation-drawer temporary right height="auto" v-model="drawer_" app style="z-index:99;">
      <v-list dense>
        <!-- 1단 메뉴 -->
<!-- 
        <v-list-item
          v-for="(item, i) in menu_list()"
          :key="i"
          link
          @click= "item.link !== $route.path ? goto(item.link) : drawer_=false ">
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
 -->
        <!-- 2단 메뉴 -->
        <v-list-group
          v-for="item in menu_list"
          :key="item.title"
          v-model="item.active"
          :append-icon="item.econ"
          :prepend-icon="item.icon"
          :hidden="item.hidden"
          no-action
          @click= "item.link && item.link !== $route.path ? goto(item.link) : item.link ? drawer_=false : '' "
        >
          <template v-slot:activator>
            <v-list-item-content>
              <!-- eslint-disable-next-line -->
              <v-list-item-title v-text="item.title" ></v-list-item-title>
            </v-list-item-content>
          </template>
  
          <v-list-item
            v-for="child in item.items"
            :key="child.title"
            @click= "child.link && child.link !== $route.path ? goto(child.link) : child.link ? drawer_=false : '' ">
            
            <v-list-item-action>
              <v-icon sm1>{{ child.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <!-- eslint-disable-next-line -->
              <v-list-item-title v-text="child.title"></v-list-item-title>
            </v-list-item-content>
            
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view/>
    </v-main>
    
    <v-footer app style="height: auto; padding: 0px; z-index:98;" class="footer">
        <v-col class="text-center pa-1">
          <div v-if="$route.path !== '/user/login' && $route.path !== '/'">
          {{ this.$store.state.resMessage }}
          </div>
          <div v-else>
          스마트 축산 PIGRO system Ver. 2.5
          </div>
        </v-col>
    </v-footer>

  </v-app>

</template>

<script>
// import { mapGetters } from "vuex";
import Apis from '@/api/apis';
// import InfoAgree from '@/components/InfoAgree.vue';
// import VueCookies from "vue-cookies";

export default {
  name: 'App',
  components: {
    // InfoAgree
  },
  data() {
    return {
        home_logo: require("@/assets/home_logo.svg"),

        icon_logout: require("@/assets/home/icon_logout_white.svg"),
        icon_login: require("@/assets/home/icon_login_white.svg"),

        icon_home_white: require("@/assets/home/icon_home_white.svg"),
        logo_white: require("@/assets/images/logo_white.svg"),
        
        // 드로우 메뉴 리스트
        drawer_: false,
        menu_list: [
                  ],
        menu_initial: [
                    { icon: 'mdi-monitor-eyeㅣ', econ: 'mdi-blank', active: false, title: '모니터링 요약', link: "/monitoring/summary",},          // 0
                    { icon: 'mdi-view-list', econ: 'mdi-blank', active: false, title: '지표조회', link: "/monitoring/cardroominfo", },         // 1
                    { icon: 'mdi-map-marker-radius', econ: 'mdi-blank', active: false, title: '노드 맵', link: "/SensorMap2", },   // 2
                    { icon: 'mdi-pig', econ: 'mdi-blank', active: false, title: '관리일지', link: "/dailyrecord/totalevents", },               // 3
                    { icon: 'mdi-fan', active: false, title: '환기 제어',                                                                // 4
                    // { icon: 'mdi-fan', active: false, title: '환기 제어', link: "/device/settingcontrol",                                   // 3 클라우드 임시 환기제어 비활성
                        items:  [ 
                                  { link: "/control/controlsummary", icon: "mdi-monitor", title: "환기 제어 모니터링",},
                                  { link: "/device/settingcontrol", icon: "mdi-fan", title: "컨트롤러 출력 현황" },
                                  { link: "/control/controltable", icon: "mdi-snowflake", title: "계절별 환기 설정" },
                                ],
                    },
                    { icon: 'mdi-recycle', econ: 'mdi-blank', active: false, title: '악취제어', link: "/deodorizer/DeodorizerDviceMonitoring", },   // 5
                    // { icon: 'mdi-playlist-check', econ: 'mdi-blank', active: false, title: '센서 수신 현황',link: "/device/monitorsensor2", },   // 6
                    { icon: 'mdi-brightness-7', econ: 'mdi-blank', active: false, title: '정보설정',link: "/setinfo", },                       // 7 -> 6
                    { icon: 'mdi-book-open-page-variant', econ: 'mdi-blank', active: false, title: '기초 가이드', link: "/setup/basedata", },   // 8 -> 7
                    { icon: 'mdi-account-key', econ: 'mdi-blank', active: false, title: '내정보 수정', link: "/user/myinfo", },                 // 9 -> 8
                    // { icon: "mdi-information-variant", econ: 'mdi-blank', active: false, title: "About", link: "/about", },                 // 10
                  ],
      };
  },

  computed: {
    // ...mapGetters(["isLogin", "drawer"]),
    loginOutText() {
      return this.$getters.isLogin ? "로그아웃" : "로그인";
    },
    setHomeBtn() {
      // console.log("setHomeBtn")      
      return this.$getters.isLogin ? this.icon_home_white : this.logo_white;
    }, 
},
  methods: {
    certify(){
      window.open("/mtraceagree","_blank","width=550,height=600, scrollbars=no, resizable=no, toolbars=no, menubar=no")
    },
    async refresh_menu() {
      this.menu_list = [];
      this.menu_initial.forEach(item => item.hidden = false);

      if (this.$getters.isLogin) {
        await Apis.listAccesableServices({      // 제공 서비스 여부
          },(res) => {
            this.menu_initial[2].hidden = !res.data.dailyrecord;
            this.menu_initial[3].hidden = !res.data.dailyrecord;
            this.menu_initial[4].hidden = !res.data.fancontrol;
            this.menu_initial[5].hidden = !res.data.deodorizer;
          }).catch( (err) => {  // API 오류 처리
              console.log("listAccesableServices API 호출 오류",err)
              this.menu_initial[3].hidden = true;
              this.menu_initial[4].hidden = true;
              this.menu_initial[5].hidden = true;
          })

        await Apis.getUserType({
          },(res) => {  
            if (res.result) {
              if (res.data < "UT04") {                  // 현장관리자, 조회자 정보설정 불가
                this.menu_initial[6].hidden = false;
                }
              else {
                this.menu_initial[2].hidden = true;
                this.menu_initial[6].hidden = true;
                // this.menu_initial[7].hidden = true;
              }  
            } else {
              this.menu_initial[2].hidden = true;
              this.menu_initial[6].hidden = true;
              // this.menu_initial[7].hidden = true;
              console.log("사용자 등급 확인 불명");
            }
          }).catch( (err) => {  // API 오류 처리
            this.menu_initial[2].hidden = true;
            this.menu_initial[6].hidden = true;
            // this.menu_initial[7].hidden = true;
            console.log("사용자 등급 확인 불능",err);
          }
        );
        
      } else {
          this.menu_initial[0].hidden = true;
          this.menu_initial[1].hidden = true;
          this.menu_initial[2].hidden = true;
          this.menu_initial[3].hidden = true;
          this.menu_initial[4].hidden = true;
          this.menu_initial[5].hidden = true;
          this.menu_initial[6].hidden = true;
          this.menu_initial[7].hidden = true;
          this.menu_initial[8].hidden = true;
          // this.menu_initial[9].hidden = true;
      }
      this.menu_list = this.menu_initial; 
    }, 
    login() {
      this.$router.push("/user/login")
    },
  
    logOut() {
      if (this.$getters.isLogin) {
        var logoutConfirm = confirm("로그아웃 하시겠습니까?");
        if (logoutConfirm) {
          // Apis.logout({
          //   session_id: this.$getters.sessionId,
          //   account: this.$getters.userInfo.account,
          //   user_name: this.$getters.userInfo.name,
          //   } ,(res) => {  // 정상처리 예제
          //     console.log(res);
          //     location.reload();
          //   }).catch( (err) => {  // 개별 API 오류 처리 예제
          //   console.log("LogOut 호출 오류");
          //   console.log(err);
          //   }) 
          // } 

          this.$dispatch("logout", {
            session_id: this.$getters.sessionId,
            account: this.$getters.userInfo.account,
            user_name: this.$getters.userInfo.name,
          }).then(() => {
            this.$router.push("/user/login");
            }).catch( (err) => {  // 개별 API 오류 처리 예제
            console.log("LogOut 호출 오류2",err);
            this.$router.push("/user/login");
            }) 
        }
      } else {
        if (this.$route.path !== "/user/login") this.$router.push("/user/login");
      }
    },
    myInfo() {
      if (this.$route.path !== "/user/myinfo") this.$router.push("/user/myinfo")
    },
    goto(path) {
      // console.log("PATH",path);
      this.drawer_ = false;
      this.$router.push(path);
    },
    toggleDrawer() {
      this.$router.push("/")
      // this.$commit("drawer", !this.drawer);
    },
    getPageName(pageName) {
      // console.log(pageName);
      switch (pageName) {
        case "/":
          return "ONESPRING";
        case "Home":
          return "ONESPRING";
        case "FarmMap":
          return "PIGRO 맵";
        case "HomeCard":
          return "ONESPRING";
        case "MainMenu":
          return "바로가기 맵";
        case "Login":
          return "ONESPRING";
        case "JoinAgree":
          return "정보수집동의";
        case "JoinInfo":
          return "가입정보";
        case "FindAccount":
          return "ID 찾기";
        case "FindPassword":
          return "비밀번호";
        case "Company":
          return "경영체 정보";
        case "ListCompanies":
          return "경영체 목록";
        case "Farm":
          return "사업장 정보";
        case "ListFarms":
          return "사업장 목록";
        case "Building":
          return "건물 정보";
        case "ListBuildings":
          return "건물 목록";
        case "DealCompany":
          return "거래처 정보";
        case "ListDealCompanies":
          return "거래처 목록";
        case "Room":
          return "구역 정보";
        case "Summary":
          return "모니터링 요약";
        case "Summary2":
          return "모니터링 요약";
        case "CardRoomInfo":
          return "지표 조회";
        case "Barometer":
          return "지표 조회";
        case "RegisterUser":
          return "사용자 관리";
        case "ListSensorOnOff":
          return "센서 ON/OFF 목록";
        // case "MonitorSensor":
        //   return "센서 수신 현황";
        // case "MonitorSensor2":
        //   return "센서 수신 현황";
        case "RegisterDevice":
          return "장치 등록  ";
        case "SettingControl":
          return "컨트롤러 출력 현황";
        case "SettingPannel":
          return "제어설정";
        case "TotalEvents":
          return "관리 일지";
        case "Event":
          return "일지입력";
        case "ListEvents":
          return "일지목록";
        case "MyInfo":
          return "내정보 수정";
        case "ChangePW":
          return "비밀번호 변경";
        case "SetInfo":
          return "정보 설정";
        case "BaseData":
          return "기초 가이드 자료";
        case "ComponentTest":
          return "부자되세요";
        case "DailyTransfer":
          return "관리일지 변경내역";
        case "ControlSummary":
          return "환기 제어 모니터링";
        case "ControlSet":
          return "환기 제어 설정";
        case "ControlTable":
          return "계절별 환기 설정";
        case "ControlTable2":
          return "계절별 환기 설정";
        case "WattageDetail":
          return "전력 데이터";
        case "CardRoomTablet":
        return "모니터링 현황판";
        case "RoomTablet":
        return "돈방별 현황판";
        case "DeodorizerMonitoring":
        return "악취 제어 모니터링";
        case "DeodorizerSet":
        return "악취 제어 설정";
        case "DeodorizerHome":
        return "악취";
        case "DeodorizerDviceMonitoring":
        return "기계실 현황";
        case "DeodorizerTest":
        return "기계실 현황";
        case "About":
        return "원스프링";
        case "RegisterInfo":
        return "공간등록";
        case "company":
        return "공간등록";
        case "farm":
        return "공간등록";
        case "building":
        return "공간등록";
        case "room":
        return "공간등록";
        case "RoomMonitor":
        return "";
        case "SensorMap":
        return "바이오플랜트 센서";
        case "SensorMap2":
        return "";
        case "AlertSet":
        return "알림 설정";
        case "MonitorEsg":
        return "ESG 모니터링";
        case "MonitorEsg2":
        return "온실가스 배출 현황";
        case "SimulationEsg":
        return "ESG 시뮬레이션";
        case "JYDeodorizer":
        return "안개분무 모니터링";
        case "JYDeodorizer2":
        return "안개분무 모니터링";
        case "AlertSet2":
        return "돈방별 알림설정";
        case "AlertPersonalSet":
        return "알림 수신 설정";
        case "AlertDataTest":
        return "알림 테스트";
        default:
          return pageName;
      }
    },
  },

};
</script>

<style> 
@import "./style/base.css";
</style>

<style lang="scss">
@import "@/style/base.scss";
</style>
